// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: "6.1.1",
  notificationsCount: 15,
  production: true,
  purchaseLink: 'https://promsys.thrivecart.com/bar-helper-copy-3/',
  reCaptchaKey: '6LehhfgoAAAAAMFcJ9uMa3msGYP1Enqb1DsoYfNg',
  firebase: {
    apiKey: "AIzaSyBnrViARu7CDh99DYW33wYYj6Isk34FV8A",
    authDomain: "barhelper-5d620.firebaseapp.com",
    databaseURL: "https://barhelper-5d620.firebaseio.com",
    projectId: "barhelper-5d620",
    storageBucket: "barhelper-5d620.appspot.com",
    messagingSenderId: "690295987921",
    appId: "1:690295987921:web:18a03c88c1ecbea1792ff0",
    measurementId: "G-FEGJ8K0RRC",
    vapidKey: 'BPPPbP_ReGiTspA4C8e7kFxr5u3kFZ67ydvnU0_xkDt1fvLnk_XPcUPugxORhZcn3vUnIos3hJrvd4IW5C0xuI4'
  },
  untappd: {
    clientId: '4E9D784DA72CA65615E0634F84C0278B711D8755',
    clientSecret: '688B76E4F492B1EC6AB2B9129F48F93EE6F05454',
    redirectUrl: 'https://stage.barhelper.app/settings/untappd',
    authenticateUrl: 'https://untappd.com/oauth/authenticate/?client_id=' +
      '4E9D784DA72CA65615E0634F84C0278B711D8755' +
      '&response_type=code&redirect_url=https://stage.barhelper.app/settings/untappd',
    authorizeUrl: 'https://untappd.com/oauth/authorize/?client_id=' +
      '4E9D784DA72CA65615E0634F84C0278B711D8755' +
      '&client_secret=' +
      '3688B76E4F492B1EC6AB2B9129F48F93EE6F05454' +
      '&response_type=code&redirect_url=https://stage.barhelper.app/settings/untappd&code='
  }, sentry: {
    dsn: 'https://07af8afc623a403a93daa94b692a945b@o413889.ingest.sentry.io/5302446',
    disableLogging: false,
    projectName: 'barhelper-stage'
  },
  onTapTvUrl: 'https://stage.tv.barhelper.app',
  onTap2TvUrl: 'https://stage.ontap.barhelper.app/tv',

};
