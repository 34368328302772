import { Component, OnInit, OnDestroy, ApplicationRef, Pipe, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import packageJson from '../../package.json';

import { Platform, MenuController, AlertController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@angular/fire/auth';
import { IUser } from './models/user';
import { UserService } from './services/dal/user.service';
import { PlaatoSchedulerService } from './services/plaato-scheduler.service';
import { IBar } from './models/bar';
import { BeerstyleService } from './services/dal/beerstyle.service';
import { SubscriptionService } from './services/dal/subscription.service';
import { SwUpdate } from '@angular/service-worker';
import { catchError, filter, first, tap } from 'rxjs/operators';
import { interval, concat, Subscription, Observable } from 'rxjs';
import { SubscriptionHandler } from './services/subscriptionHandler.service';
import { Store } from '@ngrx/store';
import * as fromUser from './state/user';
import * as fromBar from './state/bar/reducer';
import * as fromStatistics from './state/statistics/reducer';
import * as fromBeverage from './state/beverage/reducer';
import * as fromBeerStyle from './state/beer-style/reducer';
import * as UserActions from './state/user/actions';
import * as fromLocation from './state/location/reducer';
import * as fromKegs from './state/keg/reducer';
import * as fromTaps from './state/tap/reducer';
import * as fromExternalApiSettings from './state/external-api-settings/reducer';

import * as fromUnits from './state/units';
import * as fromPlaato from './state/plaato/reducer';
import * as fromPlaato2 from './state/plaato2/reducer';
import * as fromKegtrons from './state/kegtron/reducer';
import * as fromCustomKegMon from './state/custom_keg_mon/reducer';
import * as fromSubscription from './state/subscription';
import * as fromOnTapAdvanced from './state/on_tap_advanced';
import * as fromFont from './state/font';
import * as fromOnTapTemplate from './state/ontap-template';
import * as fromOnTapAdvancedCardTemplate from './state/on_tap_advanced_card_template';
import * as fromNotifications from './state/notification';

import { IBeverage } from './models/beverage';
import { IBarSettings } from './models/barSettings';
import { IStatistics } from './models/statistics';
import { IUnits } from './models/units';
import { IKeg } from './models/keg';
import { ITap } from './models/tap';
import { ILocation } from './models/location';
import { IPlaato } from './models/plaato';
import { IKegtron } from './models/kegtron';
import { ICustomKegMon } from './models/customKegMon';
import { IExternalApiSettings } from './models/externalApiSettings';
import { ISubscription } from './models/subscription';
import { DalHelper } from './services/dal.helper';
import { IBeerStyle } from './models/beerstyle';
import * as fromBarSettings from 'app/state/bar-settings/reducer';
import * as fromStripeCustomer from 'app/state/stripeCustomer';
import { ToastService } from './services/toast.service';
import { IFont } from './models/font';
import { IOnTapAdvancedCardTemplate, IOnTapTemplate } from './models/on-tap-template';
import { IOnTap2 } from './models/on-tap';
import { SplashScreen } from '@capacitor/splash-screen';
import { IAppPage } from './models/appPage';
import * as fromStripeProduct from 'app/state/stripeProduct';
import * as fromStripePrice from 'app/state/stripePrice';
import * as fromStripeSubscription from 'app/state/stripeSubscription';
import { addItem } from './state/beer-style/actions';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Capacitor } from '@capacitor/core';
import { IPlaato2 } from './models/plaato2';
import * as fromCustomPlaatoKeg from 'app/state/plaato2';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './app.component.scss',
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss',
  ],

})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectedIndex = 0;
  public appPages: IAppPage[] = [
    {
      id: 0,
      profileItem: false,
      isPremium: false,
      title: 'Home',
      url: '/home',
      icon: 'home',
    },
    {
      id: 1,
      profileItem: false,
      isPremium: false,
      title: 'Inventory',
      url: '/inventory',
      icon: 'beer',
    },
    {
      id: 2,
      profileItem: false,
      isPremium: false,
      title: 'Taps',
      url: '/taps',
      src: 'assets/icons/outline/tap.svg'
    },
    {
      id: 3,
      profileItem: false,
      isPremium: false,
      title: 'Kegs',
      url: '/kegs',
      src: 'assets/icons/outline/keg.svg'
    },
    {
      id: 4,
      title: 'On-Tap TV',
      url: '/tv/advanced',
      icon: 'tv',
      isPremium: true,
      profileItem: false,
    },
    {
      id: 5,
      title: 'Settings',
      url: '/settings',
      icon: 'settings',
      isPremium: false,
      profileItem: false,
    },
    {
      id: 6,
      title: 'Subscription',
      url: '/subscription',
      icon: 'star',
      isPremium: false,
      profileItem: true
    },
    {
      id: 7,
      title: 'Notifications',
      badge: 3,
      url: '/notifications',
      icon: 'notifications',
      isPremium: false,
      profileItem: false
    },
  ];

  user$!: Observable<User | any>;
  barId$!: Observable<string>;
  hasPremium$!: Observable<boolean>;
  bar$ = this.store.select(fromBar.getBar);
  locations$!: Observable<ILocation[]>;
  kegs$!: Observable<IKeg[]>;
  taps$!: Observable<ITap[]>;
  tapError$!: Observable<any>
  plaatos$!: Observable<IPlaato[]>;
  customplaatos$!: Observable<IPlaato2[]>;
  kegtrons$!: Observable<IKegtron[]>;
  beerStyles$!: Observable<IBeerStyle[]>;
  customKegMons$!: Observable<ICustomKegMon[]>;
  beverages$!: Observable<IBeverage[]>
  barSettings$!: Observable<IBarSettings[]>
  statistics$!: Observable<IStatistics[]>
  units$ = this.store.select(fromUnits.getUnits);
  externalApiSettings$!: Observable<IExternalApiSettings | undefined>;
  subscription$!: Observable<ISubscription | undefined>;
  fonts$!: Observable<IFont[]>;
  ontapTemplates$!: Observable<IOnTapTemplate[]>;
  onTapAdvancedCardTemplates$!: Observable<IOnTapAdvancedCardTemplate[]>;
  ontapAdvanced$!: Observable<IOnTap2[]>;

  readNotifications$ = this.store.select(fromNotifications.selectReadItems);
  unreadNotifications$ = this.store.select(fromNotifications.selectUnReadItems);
  stripeCustomer$ = this.store.select(fromStripeCustomer.selectCurrentItem);
  stripeSubscriptions$ = this.store.select(fromStripeSubscription.selectAllItems);

  hasSubscription$ = this.store.select(fromSubscription.hasValidSupscription);
  plaatos2$ = this.store.select(fromPlaato2.selectAllItems);


  stripeProducts$ = this.store.select(fromStripeProduct.selectAllItems);
  // .pipe(
  //   tap((items) => {
  //     for (let item of items) {
  //       this.store.dispatch(fromStripePrice.loadItems({ productId: item.id }));
  //     }
  //   })
  // );




  notifications$ = this.store.select(fromNotifications.selectUnReadItems).pipe(
    tap((items) => {
      const counter = items.length;

      let item = this.appPages.find(x => x.id === 7);
      if (item) {
        const newNotifications = counter > 9 ? '9+' : counter;

        item = {
          ...item,
          badge: newNotifications
        };

        this.appPages = [
          ...this.appPages.filter(x => x.id < 7),
          item,
          ...this.appPages.filter(x => x.id > 7),
        ];
      }
    })
  );

  bar!: IBar;
  user!: IUser;
  textDir = 'ltr';
  // isPremium?: boolean = null;
  thriveCartPurchaseLink!: string;


  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  private subs: Subscription[] = [];

  public version: string = packageJson.version;

  constructor(
    private ccService: NgcCookieConsentService,
    private toastService: ToastService,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private plaatoSchedulerService: PlaatoSchedulerService,
    private userService: UserService,
    private beerStyleService: BeerstyleService,
    private platform: Platform,
    private menuController: MenuController,
    private alertController: AlertController,
    private subscriptionService: SubscriptionService,
    private sh: SubscriptionHandler,
    private store: Store,
    private dalHelper: DalHelper
  ) {
    this.initializeApp();
  }


  ngAfterViewInit(): void {


  }

  opencc() {
    this.ccService.fadeIn();
    //fadeOut to hide
  }

  initializeApp() {

    this.user$ = this.store.select(fromUser.getUser);


    const userSub$ = this.user$.subscribe((user) => {
      if (Boolean(user)) {
        this.store.dispatch(fromStripeCustomer.loadItem({ id: user.uid }));
        this.store.dispatch(fromStripeSubscription.loadAllItems({ id: user.uid }));
      }
    });
    this.subs.push(userSub$);
    this.sh.addSubscription(userSub$);




    this.barId$ = this.store.select(fromUser.getBarId);
    this.bar$ = this.store.select(fromBar.getBar);
    this.barSettings$ = this.store.select(fromBarSettings.getAllItems);
    this.locations$ = this.store.select(fromLocation.getAllItems);
    this.kegs$ = this.store.select(fromKegs.selectAllItems);
    this.taps$ = this.store.select(fromTaps.selectAllItems);
    this.tapError$ = this.store.select(fromTaps.selectError).pipe(
      filter((data: any) => !!data),
      tap(async (data) => await this.toastService.errorFromFirebaseWithTimer({ ...data }, 3000))
    );

    this.statistics$ = this.store.select(fromStatistics.getItemsForHomepage(2));
    this.beverages$ = this.store.select(fromBeverage.selectAllItems);
    this.beerStyles$ = this.store.select(fromBeerStyle.getAllItems);

    this.externalApiSettings$ = this.store.select(fromExternalApiSettings.getItem);
    this.subscription$ = this.store.select(fromSubscription.getItem);

    this.hasPremium$ = this.store.select(fromSubscription.getHasPremium);


    this.plaatos$ = this.store.select(fromPlaato.selectAllItems);
    this.customplaatos$ = this.store.select(fromCustomPlaatoKeg.selectAllItems);
    this.kegtrons$ = this.store.select(fromKegtrons.selectAllItems);
    this.customKegMons$ = this.store.select(fromCustomKegMon.selectAllItems);

    this.fonts$ = this.store.select(fromFont.selectAllItems);
    this.ontapAdvanced$ = this.store.select(fromOnTapAdvanced.selectAllItems);
    this.ontapTemplates$ = this.store.select(fromOnTapTemplate.selectAllItems);
    this.onTapAdvancedCardTemplates$ = this.store.select(fromOnTapAdvancedCardTemplate.selectAllItems);


    const barSub$ = this.bar$.subscribe((bar) => {
      if (Boolean(bar)) {
        this.setDarkMode(bar?.darkMode ?? false);
      }
    });
    this.subs.push(barSub$);
    this.sh.addSubscription(barSub$);


    if (this.swUpdate.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      // const everySixHours$ = interval(15 * 1000); // 15 sekunder
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      const six$ = everySixHoursOnceAppIsStable$.subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
      this.subs.push(six$);
      this.sh.addSubscription(six$);
    }


    this.platform.ready().then(() => {



    });
  }

  stopPlaato() {
    this.plaatoSchedulerService.stop();
  }

  handleRefresh(event: any) {
    this.store.dispatch(UserActions.loadUser());
    this.store.dispatch(UserActions.loadBarId());

    const barIdSub$ = this.barId$.subscribe(async (barId) => {
      if (barId) {
        this.dalHelper.dispatchAllDals(barId);
        event.target.complete();
      }
    });
    this.subs.push(barIdSub$);
    this.sh.addSubscription(barIdSub$);
  }

  // Utility function to parse the query string into an object
  private parseQueryParams(queryString: string): { [key: string]: string } {
    const params: { [key: string]: string } = {};
    const searchParams = new URLSearchParams(queryString);

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  ngOnInit() {
    if (!window.location.hash) {
      debugger;
      const path = window.location.pathname;
      const queryParams = this.parseQueryParams(window.location.search);

      // this.router.navigate([path], {
      //   queryParams: queryParams,
      //   replaceUrl: true
      // });
    }

    const isNative = Capacitor.isNativePlatform();
    if (isNative || true) {
      this.ccService.destroy();
    } else {

      console.info('this.ccService.getConfig().content', this.ccService.getConfig());


      // Cookie Consent
      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        () => {
          console.info('open popup');

          // you can use this.ccService.getConfig() to do stuff...
        });

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          console.info('close popup');

        });

      this.initializingSubscription = this.ccService.initializing$.subscribe(
        (event: NgcInitializingEvent) => {
          // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
          console.info(`initializing: ${JSON.stringify(event)}`);
        });

      this.initializedSubscription = this.ccService.initialized$.subscribe(
        () => {
          // the cookieconsent has been successfully initialized.
          // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
          console.info(`initialized: ${JSON.stringify(event)}`);
        });

      this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize...
          console.info(`initializationError: ${JSON.stringify(event.error?.message)}`);
        });

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          console.info('statusChange: ', event);

        });

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          console.info('revokeChoice');

        });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          console.info('noCookieLaw: ', event);

        });
    }




    this.store.dispatch(UserActions.loadUser());
    this.store.dispatch(UserActions.loadBarId());


    this.user$.pipe(
      tap((user) => {
        if (Boolean(user)) {
          this.store.dispatch(fromStripeCustomer.loadItem({ id: user.uid }));
        }

      })
    )

    const barIdSub$ = this.barId$.subscribe(async (barId) => {
      if (barId) {
        this.dalHelper.dispatchAllDals(barId);
        SplashScreen.hide();

        this.plaatoSchedulerService.startWithBarId(barId);

      }
    });
    this.subs.push(barIdSub$);
    this.sh.addSubscription(barIdSub$);



    if (this.swUpdate.isEnabled) {
      const swUpdate$ = this.swUpdate.versionUpdates.subscribe(async (event) => {
        await this.confirmReloadNewVersion(event);
      });
      this.subs.push(swUpdate$);
      this.sh.addSubscription(swUpdate$);
    }

    const userSub$ = this.authService.user$.subscribe(async (user) => {
      if (user != null) {
        this.userService.getMetadataUser(user.uid).subscribe(async () => {
          const barId = await this.authService.getUserBarId();
          //this.subscriptionService.userHasPremium(barId, true);

          //this.user = user;
          // this.getContent(user);
          this.createThrivecartPurchaseLink(barId, user.uid, user?.email ?? '');
          this.checkSubscriptionTimer(barId);
          if (!user.emailVerified) {
            this.router.navigate(['verify-email']);
          }
        });
      }
      this.subs.push(userSub$);
      this.sh.addSubscription(userSub$);
    });

    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }


  ngOnDestroy() {
    for (let index = 0; index < this.subs.length; index++) {
      const sub = this.subs[index];
      if (sub) { sub.unsubscribe(); }
    }

    //Cookie Consent
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();


  }



  async signOut() {
    //    this.stopPlaato();
    this.menuController.close();
    this.menuController.enable(false);
    this.plaatoSchedulerService.stop();
    await this.authService.logout().then(() => {
      this.router.navigate(['login']);
    });
  }

  import() {
    this.beerStyleService.import();
  }

  // private getContent(user: User) {
  //   if (!this.authService.barId) {
  //     this.authService.getUserTokenClaim().then((claim) => {
  //       if (claim.claims && claim.claims.barOwner && claim.claims.barOwner.length === 1) {
  //         this.authService.barId = claim.claims.barOwner[0];
  //         const bar$ = this.barService.getItem(this.authService.barId).subscribe((item) => {
  //           this.bar = item;
  //         });
  //         this.subs.push(bar$);
  //         this.sh.addSubscription(bar$);
  //       } else {
  //         console.error('Error: No bar!');
  //       }
  //     });
  //   } else {
  //     // const barItem$ = this.barService.getItem(this.authService.barId).subscribe((item) => {
  //     //   this.bar = item;
  //     //   if(this.bar) {
  //     //     this.setDarkMode(this.bar.darkMode);
  //     //   }

  //     // });
  //     // this.subs.push(barItem$);
  //     // this.sh.addSubscription(barItem$);

  //   }
  // }

  private setDarkMode(darkMode: boolean) {
    if (!darkMode) {
      darkMode = false;
    }
    document.body.classList.toggle('dark', darkMode);
  }


  private createThrivecartPurchaseLink(barid: string, userId: string, email: string) {
    this.thriveCartPurchaseLink = this.subscriptionService.createThrivecartPurchaseLink(barid, userId, email);
  }

  private checkSubscriptionTimer(barId: string) {
    // every 12 hours: 43200000
    setInterval(() => this.subscriptionService.userHasPremium(barId, true), 43200000);
  }


  async confirmReloadNewVersion(event: any) {

    if (event.type !== 'VERSION_READY') {
      return;
    }

    const newVersion = event.latestVersion.appData.version;

    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      header: 'Update avaliable!',
      subHeader: 'New version: ' + newVersion,
      message: 'Do you want to load the new version?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (cancel) => {
          }
        }, {
          text: 'Yes please',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }


  gotTo(hasPremium: boolean, page: any, i: number): void {
    this.selectedIndex = i;
    if (hasPremium || !page.isPremium) {
      this.router.navigate([page.url]);
    } else {
      this.router.navigate(['subscribe']);
    }
  }
}
